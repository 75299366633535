<template>
    <!-- Body -->
    <validation-observer #default="{ handleSubmit }" ref="refFormObserver">

        <!-- Form -->
        <b-form class="p-2" @submit.prevent="handleSubmit(onSubmit)" @reset.prevent="resetForm">
          <b-row>
            <!-- <b-col md="12">
              <b-form-group label="" label-for="task-call-type">
                <b-form-radio-group v-model="taskLocal.call_type" aria-controls="ex-disabled-readonly">
                    <b-form-radio value="inbound">
                        Гаднаас залгасан
                    </b-form-radio>
                    <b-form-radio value="outbound">
                        Гадагшаа залгасан
                    </b-form-radio>
                </b-form-radio-group>
              </b-form-group>
            </b-col> -->
            <b-col md="6">
              <validation-provider
                #default="validationContext"
                name="Утас"
                rules="required"
              >
                <b-form-group
                  label="Утас"
                  label-for="phone"
                >
                  <b-input-group>
                      <b-form-input id="phone"
                    v-model="taskLocal.phone"
                    autofocus
                    :state="getValidationState(validationContext)"
                    trim
                    placeholder="Утас"
                    @blur="updateCustomer(taskLocal.phone)"/>
                      <b-input-group-append v-if="customerUrl">
                        <b-button variant="outline-primary" @click="gotoAdminApp(customerUrl)">
                          Админ
                        </b-button>
                      </b-input-group-append>
                    </b-input-group>

                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="6">
                <validation-provider
                  #default="validationContext"
                  name="Овог"
                >
                  <b-form-group
                    label="Овог"
                    label-for="lastname"
                  >
                    <b-form-input
                      id="lastname"
                      v-model="taskLocal.customer.lastname"
                      autofocus
                      :state="getValidationState(validationContext)"
                      trim
                      placeholder="Овог"
                    />

                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
            </b-col>
            <b-col md="6">
              <validation-provider
                #default="validationContext"
                name="Нэр"
              >
                <b-form-group
                  label="Нэр"
                  label-for="firstname"
                >
                  <b-form-input
                    id="firstname"
                    v-model="taskLocal.customer.firstname"
                    autofocus
                    :state="getValidationState(validationContext)"
                    trim
                    placeholder="Нэр"
                  />

                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="6">
              <validation-provider
                #default="validationContext"
                name="Регистрийн дугаар"
              >
                <b-form-group
                  label="Регистрийн дугаар"
                  label-for="register_number"
                >
                  <b-form-input
                    id="register_number"
                    v-model="taskLocal.customer.register_number"
                    autofocus
                    :state="getValidationState(validationContext)"
                    trim
                    placeholder="Регистрийн дугаар"
                  />

                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <!-- Title -->
            <!-- <validation-provider #default="validationContext" name="Гарчиг" rules="required">
                <b-form-group label="Гарчиг" label-for="task-title">
                    <b-form-input id="task-title" v-model="taskLocal.title" autofocus
                        :state="getValidationState(validationContext)" trim placeholder="Гарчиг" />

                    <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                </b-form-group>
            </validation-provider> -->
            
            <!--Category -->
            <b-col md="6">
              <validation-provider
                #default="validationContext"
                name="Ангилал"
                rules="required"
              >
              <b-form-group
                label="Ангилал"
                label-for="categories"
              >
                <treeselect id="categories" :state="getValidationState(validationContext)" required v-model="taskLocal.categoryIds" :multiple="true" :options="refCategoryOptions" :disable-branch-nodes="true" search-nested label="name" placeholder="Ангилал"/>
                <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
              </b-form-group>
              </validation-provider>
            </b-col>

            <!--Products -->
            <b-col md="6">
              <validation-provider
                #default="validationContext"
                name="Бүтээгдэхүүн"
                rules="required"
              >
                <b-form-group
                  label="Бүтээгдэхүүн"
                  label-for="product"
                >
                  <treeselect id="product" :state="getValidationState(validationContext)" required v-model="taskLocal.productIds" :multiple="true" :options="refProductOptions" :disable-branch-nodes="true" search-nested label="name" placeholder="Бүтээгдэхүүн"/>
                  <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <!--Tag -->
            <b-col md="6">
              <b-form-group
                label="Таг"
                label-for="tag"
              >
                <v-select
                  v-model="taskLocal.tagIds"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  multiple
                  :options="refTagOptions"
                  :reduce="option => option.id"
                  label="name"
                  input-id="tags"
                  placeholder="Таг"
                />
              </b-form-group>
            </b-col>

            <!--Priority -->
            <b-col md="6">
              <b-form-group
                label="Ач холбогдол"
                label-for="priority"
              >
                <v-select
                  v-model="taskLocal.priority"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="refPriorityOptions"
                  :reduce="option => option.name"
                  label="name"
                  input-id="priority"
                  placeholder="Ач холбогдол"
                />
              </b-form-group>
            </b-col>
            
            <!-- Description -->
            <b-col md="12">
              <b-form-group label="Асуудал" label-for="quil-content">
                <b-form-textarea v-model="taskLocal.description" rows="3" placeholder="Асуудал" />
              </b-form-group>
            </b-col>
            
            <!--Team -->
            <b-col md="6">
              <b-form-group label="Баг" label-for="team">
                <v-select v-model="taskLocal.team" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="teamOptions" :reduce="option => option.name" label="name"
                  input-id="team" @input="changeTeam($event)" placeholder="Баг" />
              </b-form-group>
            </b-col>
            
            <!-- Assignee -->
            <b-col md="6">
              <b-form-group label="Хариуцагч" label-for="assignee">
                <v-select v-model="taskLocal.assignee" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="userOptions" :reduce="option => option.username" label="username" class="assignee-selector" input-id="assignee"
                    placeholder="Хариуцагч">

                    <!-- <template #option="{ avatar, fullName }">
                  <b-avatar
                    size="26"
                    :src="avatar"
                  />
                  <span class="ml-50 d-inline-block align-middle"> {{ fullName }}</span>
                </template>

                <template #selected-option="{ avatar, fullName }">
                  <b-avatar
                    size="26"
                    :src="avatar"
                    :variant="`light-${resolveAvatarVariant(taskLocal.tags)}`"
                    :text="avatarText(fullName)"
                  />

                  <span class="ml-50 d-inline-block align-middle"> {{ fullName }}</span>
                </template> -->
                </v-select>
              </b-form-group>
            </b-col>

            <!-- due Date -->
            <b-col md="6">
              <validation-provider #default="validationContext" name="Дуусах хугацаа" rules="required">
                <b-form-group label="Дуусах хугацаа" label-for="due-date">
                    <flat-pickr id="due-date" v-model="taskLocal.due_date" class="form-control"
                    :config="{ enableTime: true, dateFormat: 'Y-m-d H:i' }" :state="getValidationState(validationContext)" />
                    <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="6" v-if="taskLocal.call_type === 'outbound'">
              <validation-provider #default="validationContext" name="Issue title" rules="required">
                <b-form-group label="Төлөх эрмэлзлэл" label-for="pay-score">
                    <b-form-spinbutton id="pay-score" v-model="taskLocal.pay_score" min="1" max="100" :state="getValidationState(validationContext)"/>
                    <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="6" v-if="taskLocal.call_type === 'outbound'">
              <b-form-group label="Эргэн холбогдох">
                <flat-pickr v-model="taskLocal.promised_at" class="form-control"
                    :config="{ enableTime: true, dateFormat: 'Y-m-d H:i' }" :disabled="taskLocal.call_type === 'inbound'" />
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group label="Файл хавсаргах">
                <el-upload
                  class="upload-demo"
                  ref="uploadImage"
                  action="#"
                  :show-file-list="false"
                  :auto-upload="false"
                  :on-change="saveImage"
                >
                <b-list-group>
                  <b-list-group-item v-for="(attachment, index) of taskLocal.attachmentNames" :key="index" class="d-flex justify-content-between align-items-center">
                    <span>{{ attachment }}</span>
                    <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" type="reset" variant="outline-primary" size="sm" @click="removeAttachment(attachment)">
                      <feather-icon icon="DeleteIcon" />
                    </b-button>
                  </b-list-group-item>
                </b-list-group>
                <el-button slot="trigger" size="small" type="primary">select file</el-button>
                </el-upload>
              </b-form-group>
            </b-col>
            <b-col md="12">
              <!-- Form Actions -->
              <div class="d-flex mt-2">
                <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="mr-2" type="submit">
                    {{ taskLocal.id ? 'Өөрчлөх' : 'Нэмэх ' }}
                </b-button>
                <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" type="reset" variant="outline-secondary">
                    Буцаах
                </b-button>
              </div>
            </b-col>  
          </b-row>
        </b-form>
    </validation-observer>
</template>

<script>
import { BCol, BRow, BSidebar, BForm, BFormGroup, BFormInput, BInputGroup, BInputGroupAppend, BInputGroupPrepend, BAvatar, BButton, 
  BFormInvalidFeedback, BFormRadioGroup, BFormRadio, BFormSpinbutton, BFormTextarea, BListGroup, BListGroupItem
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import Ripple from 'vue-ripple-directive'
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate'
import getBase64 from '@/utils/getBase64.js'
extend('required', {
  ...required,
  message: 'Заавал бөглөх талбар бөглөөгүй байна'
});

// Add the email rule
extend('email', {
  ...email,
  message: 'Заавал и-мэйл бүтэцтэй байна'
});
import { required, email, url } from '@validations'
import { avatarText } from '@core/utils/filter'
import formValidation from '@core/comp-functions/forms/form-validation'
import { ref, toRefs } from '@vue/composition-api'
import { quillEditor } from 'vue-quill-editor'
import useTaskHandler from '@/views/todo/useTaskHandler'
import Treeselect from '@riophae/vue-treeselect'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
// import the styles
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import store from '@/store'

export default {
    components: {
        // BSV
        BRow,
        BCol,
        BButton,
        BSidebar,
        BForm,
        BFormGroup,
        BFormInput,
        BInputGroup,
        BInputGroupAppend,
        BInputGroupPrepend,
        BListGroup,
        BListGroupItem,
        BAvatar,
        BFormInvalidFeedback,
        BFormRadioGroup,
        BFormRadio,
        BFormSpinbutton,
        BFormTextarea,
        // 3rd party packages
        vSelect,
        flatPickr,
        quillEditor,
        Treeselect,

        // Form Validation
        ValidationProvider,
        ValidationObserver,
    },
    directives: {
        Ripple,
    },
    //   model: {
    //     prop: 'isTaskHandlerSidebarActive',
    //     event: 'update:is-task-handler-sidebar-active',
    //   },
    props: {
        // isTaskHandlerSidebarActive: {
        //   type: Boolean,
        //   required: true,
        // },
        task: {
            type: Object,
            required: true,
        },
        clearTaskData: {
            type: Function,
            required: true,
        }
    },
    created () {
        store.dispatch('app-todo/fetchRefTags', {})
            .then(response => {
                this.refTagOptions = response.data.response
            })

        store.dispatch('app-todo/fetchRefCategoriesWithChildren', {})
            .then(response => {
                this.refCategoryOptions = response.data.response.map(x => { return { ...x, label: x.name } })
            })

        store.dispatch('app-todo/fetchRefProductsWithChildren', {})
            .then(response => {
                this.refProductOptions = response.data.response.map(x => { return { ...x, label: x.name } })
            })

        store.dispatch('app-todo/fetchTeamsByFilter', { organization_name: 'Нөмөр Кредит ББСБ' })
            .then(response => {
                this.teamOptions = response.data.response
            })

        store.dispatch('app-todo/fetchRefTags', {})
            .then(response => {
                this.refTagOptions = response.data.response
            })

        store.dispatch('app-todo/fetchRefPriorities', {})
            .then(response => {
                this.refPriorityOptions = response.data.response
            })
    },
    data () {
        return {
            userOptions: [],
            teamOptions: [],
            refTagOptions: [],
            refCategoryOptions: [],
            refProductOptions: [],
            refPriorityOptions: [],
            required,
            email,
            url,
            value: null,
            attachments: []
        }
    },
    setup (props, { emit }) {
        const customerUrl = ref(null)
        const {
            taskLocal,
            resetTaskLocal,

            // UI
            assigneeOptions,
            onSubmit,
            tagOptions,
            resolveAvatarVariant,
        } = useTaskHandler(toRefs(props), emit)
        const {
            refFormObserver,
            getValidationState,
            resetForm,
            clearForm,
        } = formValidation(resetTaskLocal, props.clearTaskData)

        const editorOption = {
            modules: {
                toolbar: '#quill-toolbar-home',
            },
            placeholder: 'Дэлгэрэнгүй бичих',
        }
        // updateCustomer(taskLocal.phone)
        const updateCustomer = param => {
          customerUrl.value = null
          if (param!= null && param != undefined) {
            store.dispatch('app-todo/getCustomer', { phone: param, ticket_id: null })
            .then(response => {
              if (response.data.response) {
                  taskLocal.value.customer = response.data.response
                  taskLocal.value.customer.customer_id = response.data.response.id
                  taskLocal.value.customer.customer_per_id = response.data.response['per.id']
                  taskLocal.value.customer.phone = param
                  console.log("taskLocal.value.customer", taskLocal.value.customer, param)
                  customerUrl.value = "https://admin.numur.mn/#/admin/customers/" + taskLocal.value.customer.customer_id + "/detail"
                  emit('change-customer-phone', taskLocal.value.customer.phone)
              } else {
                // this.taskLocal.customer = ''
                taskLocal.value.customer.firstname = ''
                taskLocal.value.customer.lastname = ''
                taskLocal.value.customer.register_number = ''
                taskLocal.value.customer.customer_id = null
                taskLocal.value.customer.customer_per_id = null
                taskLocal.value.customer.phone = param
                customerUrl.value = null
                emit('change-customer-phone', null)
              }
            })
          }
        }

        const gotoAdminApp = param => {
          window.open(param, '_blank');
        }

        return {
            // Add New
            taskLocal,
            onSubmit,
            assigneeOptions,
            tagOptions,
            updateCustomer,

            // Form Validation
            resetForm,
            clearForm,
            refFormObserver,
            getValidationState,

            // UI
            editorOption,
            resolveAvatarVariant,

            // Filter/Formatter
            avatarText,
            customerUrl,
            gotoAdminApp
        }
    },
    methods: {
        changeTeam (param) {
            store.dispatch('app-todo/fetchUsersByFilter', { team_name: param })
                .then(response => {
                    this.userOptions = response.data.response
                })
        },
        saveImage (file) {
          console.log(file)
          if (this.taskLocal.customer.register_number === "") {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Уучлаарай харилцагчийн мэдээлэл бөглөөгүй байна",
                icon: 'BellIcon',
                variant: 'danger',
              },
            })
            console.log(this.taskLocal)
            return
          }
          getBase64(file.raw).then(image => {
            const base64 = image.split('base64,')
            let payload = {
              file: base64[1], 
              file_name: file.name,
              register_number: this.taskLocal.customer.register_number
            }
            store.dispatch('app-todo/fileUpload', payload).then((response) => {
              console.log(response.data.response)
              this.taskLocal.attachmentNames.push(response.data.response)
            }).catch(function (error) {
              console.log("errr". error)
            })
          })
        },
        removeAttachment (fileName) {
          let fileIndex = this.taskLocal.attachmentNames.indexOf(fileName)
          this.taskLocal.attachmentNames.splice(fileIndex, 1)
        }
    }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/quill.scss';
</style>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include';

.assignee-selector {
    ::v-deep .vs__dropdown-toggle {
        padding-left: 0;
    }
}

#quil-content ::v-deep {
    >.ql-container {
        border-bottom: 0;
    }

    +#quill-toolbar-home {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        border-bottom-left-radius: $border-radius;
        border-bottom-right-radius: $border-radius;
    }
}
</style>