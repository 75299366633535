<template>
    <b-table
        ref="refTaskListTable"
        class="position-relative __ticket_list"
        :items="fetchTasks(appUsername)"
        responsive
        :fields="tableColumns"
        primary-key="id"
        show-empty
        empty-text="No matching records found"
      >
    </b-table>
</template>

<script>
import { BTable } from 'bootstrap-vue'
import { ref, onUnmounted } from '@vue/composition-api'
import useHomeTasksList from '../useHomeTasksList'
export default {
    components: {
        BTable
    },
    props: {
        // isTaskHandlerSidebarActive: {
        //   type: Boolean,
        //   required: true,
        // },
        appUsername: {
            type: String,
            required: true,
        },
    },
    setup() {
        const {
            fetchTasks,
            tableColumns,
            perPage,
            currentPage,
            totalTasks,
            dataMeta,
            perPageOptions,
            searchQuery,
            sortBy,
            isSortDirDesc,
            refTaskListTable,
            refetchData,

            // UI
            resolveOrganizationRoleVariant,
            resolveOrganizationRoleIcon,
            resolveOrganizationStatusVariant,

            // Extra Filters
            roleFilter,
            planFilter,
            statusFilter,
        } = useHomeTasksList()

        return {
            tableColumns,
            fetchTasks
        }
    }
}
</script>

<style lang="scss">
.__ticket_list {
    font-size: 11px;
    tr th {
        padding-top: 0.72rem;
        padding-right: 0.3rem;
        padding-bottom: 0.72rem;
        padding-left: 0.3rem;
    }
    tr td {
        padding-top: 0.72rem;
        padding-right: 0.3rem;
        padding-bottom: 0.72rem;
        padding-left: 0.3rem;
    }
}
</style>