import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
import { title } from '@core/utils/filter'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useNotesList() {
  // Use toast
  const toast = useToast()

  const refNoteListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'type_name', sortable: true, label: 'Ангилал' },
    { key: 'body', sortable: true, label: 'Тэмдэглэл' },
    { key: 'created_date', sortable: true, label: 'Огноо' },
    { key: 'created_note', sortable: true, label: 'Үүсгэсэн' },
    { key: 'remain_date', sortable: true, label: 'Холбогдох огноо' },
    { key: 'is_payment_passion', sortable: true, label: 'Төлөх эрмэлзлэл' }
  ]
  const perPage = ref(10)
  const totalNotes = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)
  const roleFilter = ref(null)
  const planFilter = ref(null)
  const statusFilter = ref(null)
  const noteOptions = ref([])

  const rowCount = ref(0)


  const dataMeta = computed(() => {
    const localItemsCount = refNoteListTable.value ? refNoteListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + 1,
      to: perPage.value * (currentPage.value - 1) + rowCount.value,
      of: totalNotes.value,
    }
  })

  const refetchData = () => {
    refNoteListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery, roleFilter, planFilter, statusFilter], () => {
    refetchData()
  })

  const fetchNotes = (param) =>(ctx, callback) => {
    store
      .dispatch('app-todo/fetchNotes', {
        q: searchQuery.value,
        per_page: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
        role: roleFilter.value,
        plan: planFilter.value,
        status: statusFilter.value,
        customer_phone: param
      })
      .then(response => {
        const notes = response.data.response.items
        // noteOptions.value = []
        const per_page = response.data.response.per_page
        const current_page = response.data.response.page
        const total = response.data.response.total
        // const notea = notes.map(x => (x.status === 'active') ? x.statusText = 'Идэвхитэй' : (x.status === 'inactive') ? x.statusText = 'Идэвхигүй' : x.statusText = 'Хүлээгдэж буй')
        callback(notes)
        totalNotes.value = total
        perPage.value = per_page
        currentPage.value = current_page
        rowCount.value = notes.length

      })
      .catch((err) => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching notes list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveNoteRoleVariant = role => {
    if (role === 'subscriber') return 'primary'
    if (role === 'author') return 'warning'
    if (role === 'maintainer') return 'success'
    if (role === 'editor') return 'info'
    if (role === 'admin') return 'danger'
    return 'primary'
  }

  const resolveNoteRoleIcon = role => {
    if (role === 'subscriber') return 'NoteIcon'
    if (role === 'author') return 'SettingsIcon'
    if (role === 'maintainer') return 'DatabaseIcon'
    if (role === 'editor') return 'Edit2Icon'
    if (role === 'admin') return 'ServerIcon'
    return 'NoteIcon'
  }

  const resolveNoteStatusVariant = status => {
    if (status === 'pending') return 'warning'
    if (status === 'active') return 'success'
    if (status === 'inactive') return 'secondary'
    return 'primary'
  }

  return {
    fetchNotes,
    tableColumns,
    perPage,
    currentPage,
    totalNotes,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refNoteListTable,
    noteOptions,

    resolveNoteRoleVariant,
    resolveNoteRoleIcon,
    resolveNoteStatusVariant,
    refetchData,

    // Extra Filters
    roleFilter,
    planFilter,
    statusFilter,
  }
}
