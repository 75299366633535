<template>
    <b-row>
        <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Нэг хуудсанд</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label> бичлэг харуулах</label>
          </b-col>
        <b-table
        ref="refNoteListTable"
        class="position-relative __ticket_list"
        :items="fetchNotes(appUsername)"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
      >
      <!-- Column: is_active -->
        <template #cell(is_payment_passion)="data">
          <b-badge v-if="data.item.is_payment_passion != null && data.item.is_payment_passion != undefined"
            pill
            :variant="data.item.is_payment_passion ? 'success': 'danger'"
          >
            {{ data.item.is_payment_passion ? 'Эрмэлзлэлтэй': (data.item.is_payment_passion == null ? '' : 'Эрмэлзлэлгүй') }}
          </b-badge>
        </template>
    </b-table>
    <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Нийт {{ dataMeta.of }}-н {{ dataMeta.from }} - {{ dataMeta.to }} бичлэгийг  харуулж байна</span>
          </b-col>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalNotes"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-row>
</template>

<script>
import { BTable, BPagination, BRow, BCol, BBadge } from 'bootstrap-vue'
import vSelect from 'vue-select'
import useHomeNotesList from '../useHomeNotesList'
export default {
    components: {
        BTable,
        BPagination,
        BRow,
        BCol,
        BBadge,
        vSelect
    },
    props: {
        // isTaskHandlerSidebarActive: {
        //   type: Boolean,
        //   required: true,
        // },
        appUsername: {
            type: String,
            required: true,
        },
    },
    setup() {
        const {
            fetchNotes,
            tableColumns,
            perPage,
            currentPage,
            totalNotes,
            dataMeta,
            perPageOptions,
            searchQuery,
            sortBy,
            isSortDirDesc,
            refNoteListTable,
            refetchData,
            noteOptions,

            // UI
            resolveOrganizationRoleVariant,
            resolveOrganizationRoleIcon,
            resolveOrganizationStatusVariant,

            // Extra Filters
            roleFilter,
            planFilter,
            statusFilter,
        } = useHomeNotesList()

        return {
            tableColumns,
            fetchNotes,
            perPage,
            currentPage,
            totalNotes,
            dataMeta,
            searchQuery,
            sortBy,
            isSortDirDesc,
            refetchData, 
            perPageOptions,
            noteOptions
        }
    }
}
</script>

<style lang="scss">
.__ticket_list {
    font-size: 11px;
    tr th {
        padding-top: 0.72rem;
        padding-right: 0.3rem;
        padding-bottom: 0.72rem;
        padding-left: 0.3rem;
    }
    tr td {
        padding-top: 0.72rem;
        padding-right: 0.3rem;
        padding-bottom: 0.72rem;
        padding-left: 0.3rem;
    }
}
</style>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>