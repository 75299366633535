import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
import { title } from '@core/utils/filter'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { useRouter } from '@core/utils/utils'

export default function useOrganizationsList() {
  // Use toast
  const toast = useToast()

  const refTaskListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'category_names', sortable: true, label: 'Ангилал' },
    { key: 'product_names', sortable: true, label: 'Бүтээгдэхүүн' },

    { key: 'assignee', sortable: true, label: 'Шийдвэрлэсэн' },
    { key: 'status', sortable: true, label: 'Төлөв' },
    { key: 'description', sortable: true, label: 'Дэлгэрэнгүй' },

    // { key: 'role', sortable: true, label: 'Эрх' },
    // { key: 'organization_name', sortable: true, label: 'Байгууллага' },
    // { key: 'team_name', sortable: true, label: 'Баг' },
    // {
    //   key: 'currentPlan',
    //   label: 'Групп',
    //   formatter: title,
    //   sortable: true,
    // },
    { key: 'created_at', sortable: true, label: 'Огноо' },
    { key: 'created_by', sortable: true, label: 'Үүсгэсэн' },

    // { key: 'promised_at', label: 'Дуусах' },
  ]
  const perPage = ref(10)
  const totalTasks = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)
  const roleFilter = ref(null)
  const planFilter = ref(null)
  const statusFilter = ref(null)

  const dataMeta = computed(() => {
    const localItemsCount = refTaskListTable.value ? refTaskListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalTasks.value,
    }
  })

  const { route, router } = useRouter()
  const routeParam = computed(() => route.value.params.phone)
  console.log("routeParamrouteParam", routeParam)

  const refetchData = () => {
    refTaskListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery, roleFilter, planFilter, statusFilter], () => {
    console.log('roleFilter', roleFilter)
    refetchData()
  })
  const fetchTasks = (param) =>(ctx, callback) => {
    console.log(param, ctx, callback)
    store
      .dispatch('app-todo/fetchTasks', {
        q: searchQuery.value,
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
        role: roleFilter.value,
        plan: planFilter.value,
        status: statusFilter.value,
        customer_phone: param
      })
      .then(response => {
        // const { organizations, total } = response.data
        const tasks = response.data.response
        const total = 10
        // const organizationa = organizations.map(x => (x.status === 'active') ? x.statusText = 'Идэвхитэй' : (x.status === 'inactive') ? x.statusText = 'Идэвхигүй' : x.statusText = 'Хүлээгдэж буй')
        callback(tasks)
        totalTasks.value = total
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching organizations list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveOrganizationRoleVariant = role => {
    if (role === 'subscriber') return 'primary'
    if (role === 'author') return 'warning'
    if (role === 'maintainer') return 'success'
    if (role === 'editor') return 'info'
    if (role === 'admin') return 'danger'
    return 'primary'
  }

  const resolveOrganizationRoleIcon = role => {
    if (role === 'subscriber') return 'OrganizationIcon'
    if (role === 'author') return 'SettingsIcon'
    if (role === 'maintainer') return 'DatabaseIcon'
    if (role === 'editor') return 'Edit2Icon'
    if (role === 'admin') return 'ServerIcon'
    return 'OrganizationIcon'
  }

  const resolveOrganizationStatusVariant = status => {
    if (status === 'pending') return 'warning'
    if (status === 'active') return 'success'
    if (status === 'inactive') return 'secondary'
    return 'primary'
  }

  return {
    fetchTasks,
    tableColumns,
    perPage,
    currentPage,
    totalTasks,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refTaskListTable,

    resolveOrganizationRoleVariant,
    resolveOrganizationRoleIcon,
    resolveOrganizationStatusVariant,
    refetchData,

    // Extra Filters
    roleFilter,
    planFilter,
    statusFilter,
  }
}
